import "./App.css";
import events from "./data";

function App() {

    let year = 2024;
    return (
        <div className="App" style={{}}>
            <div className="card">
                <h1>Association Agir Par Solidarité</h1>
                accompagner, orienter, échanger avec les lycéens et collégiens
                dans leur cursus scolaire, faire des activités et des sorties
                avec eux ; accompagner les habitants dans les démarches
                administratives
            </div>

            <h1>Frise chronologique de nos actions</h1>
            <div className="container">
                {events.map(eventTab => {
                    year = year - 1;
                    return <><div className="timeline-date">🗓️ {year}</div> {eventTab.map(element => {
                        return <div className="card ">
                            <h2>{element.title}</h2>
                            <p className="card-description">{element.description}</p>
                            <img src={element.image} alt={element.image}></img>
                            <div>
                                <span>{element.date}</span>
                            </div>
                        </div>

                    })}</>
                })}
                <div className="timeline-date first-timeline-date">&nbsp;</div>
            </div>
        </div>
    );
}

export default App;
