const events = [
    [
        {
            title: "🏖️ Sortie à la plage - été 2023 🏖️",
            description: "📍 Fort-Mahon-Plage 🏖️",
            image: require("./image/plage20230812.jpg"),
            date: "12 août 2023"

        },
        {
            title: "Compiègne Plage - Barbecue 🌭",
            description: "ℹ️ Préparation et vente de sandwich au Parc de Bayser",
            image: require("./image/barbecue2023.jpg"),
            date: "21 juillet 2023"

        },
        {
            title: "Musée de l'Air et de l'Espace",
            description: "📍 Le Bourget",
            image: require("./image/citedessciences2023.jpg"),
            date: "25 juin 2023"

        },
        {
            title: "Fête de fin d'année",
            description: "ℹ️ Présentation des activités du centre Jean-Moulin",
            image: require("./image/fetefindannee2023.jpg"),
            date: "21 juin 2023"

        },
    ],
    [
        {
            title: "Soutien scolaire",
            description: "📍 Centre Jean Moulin",
            image: require("./image/soutienscolaire2021.jpg"),
            date: "Septembre 2022"

        },
        {
            title: "Sortie parc Astérix",
            description: "ℹ️ Sortie au parc",
            image: require("./image/parcasterix2022.jpg"),
            date: "23 Avril 2022"

        },
        {
            title: "Colonie de vacances multi-sports X la joie des gosses ",
            description: "📍 les Vosges(88)",
            image: require("./image/coloniedevacances2020.jpg"),
            date: "5 au 12 février 2022"

        }
    ],
    [
        {
            title: "Soutien scolaire",
            description: "📍BIJ",
            image: "",
            date: "Septembre 2021"

        },
        {
            title: "🏖️ Sortie à la plage - Août 2021 🏖️",
            description: "📍Berck-Plage 🏖️",
            image: require("./image/plage20210814.jpg"),
            date: "14 Août 2021"

        },
        {
            title: "🏖️ Sortie à la plage - Juillet 2021 🏖️",
            description: "📍Berck-Plage 🏖️",
            image: require("./image/plage20210724.jpg"),
            date: "24 Juillet 2021"

        },
        {
            title: "🏖️ Animateur montreuil sur mer 🏖️",
            description: "📍Montreuil sur mer 🏖️",
            image: require("./image/montreuilsurmer20210729.jpg"),
            date: "29 Juillet 2021"

        },
        {
            title: "Lancement du soutien scolaire",
            description: "BIJ",
            image: require("./image/soutienscolaire20210317.jpg"),
            date: "17 Mars 2021"

        },
        {
            title: "Création",
            description: "Création de l'association Agir par solidarité",
            image: require("./image/creationassociation2021.jpg"),
            date: "25 Janvier 2021"
        }
    ]
];

export default events;